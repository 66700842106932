import {makeHttpEndpoint} from "./index";
import axios from "axios";
import {SurveyElement} from "../types";

export const postSurveyElement = async(body: PostElementBody) => {
    return await axios.post(makeHttpEndpoint("/surveyelements"), body)
}

export const putSurveyElement = async(body: PutElementBody, id: string) => {
    return await axios.put(makeHttpEndpoint(`/surveyelements/${id}`), body)
}

export const deleteSurveyElement = async(id: string) => {
    return await axios.delete(makeHttpEndpoint(`/surveyelements/${id}`))
}

export type PutElementBody = {
    pageId: string
    element: SurveyElement
}

export type PostElementBody = {
    pageId: string
    element: SurveyElement
}