import React, {useCallback, useContext, useState} from "react";
import {Button, Form, FormField, Header, Icon, Input, Message, Segment, SegmentGroup} from "semantic-ui-react";
import "./SurveyAdminLogin.css"
import {useNavigate, useParams} from "react-router-dom";
import {authenticateSurveyAdmin} from "../../api/auth";
import {Text} from "../../atomic";
import {LanguageContext} from "../../context";

export const SurveyAdminLogin = () => {

    const [password, setPassword] = useState<string>("")
    const [wrongPassword, setWrongPassword] = useState(false)
    const {id, expired} = useParams()
    const {dictionary} = useContext(LanguageContext)
    const navigate = useNavigate()



    const handleSubmit = useCallback(() => {
        authenticateSurveyAdmin(id ?? "", password).then(
            _value => navigate("/admin/surveys/"+id),
            reason => {
                if(reason.response.status === 401) {
                    setWrongPassword(true)
                }
            }
        )
    }, [id, password, navigate, setWrongPassword])



    return (
        <Form id="admin" onSubmit={handleSubmit} error={wrongPassword} warning={Boolean(expired) ?? false}>
            <SegmentGroup className="login">
                <Segment>
                    <Header as="h3">
                        <Icon className="fa-solid fa-cogs" color="teal"/>
                        <Header.Content>
                           <Text textId={"adminLogin"}/>
                            <Header.Subheader>
                               <Text textId={"editSurveys"}/>
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                </Segment>
                <Segment>
                        <FormField className="login-input">
                            <label><Text textId={"password"}/></label>
                            <Input placeholder={dictionary.password} onChange={(event, data) => setPassword(data.value)}/>
                        </FormField>
                    <Message error header={dictionary.wrongPasswordHeader} content={dictionary.wrongPasswordContent}/>
                    <Message warning header={dictionary.sessionExpiredHeader} content={dictionary.sessionExpiredContent}/>
                </Segment>
                <Segment className="login-controls">
                    <Button type="submit" color="teal" content={dictionary.signin} icon="fa-sign-in" size="tiny" compact/>
                </Segment>
            </SegmentGroup>

        </Form>
    )
}
