import React from "react";
import {Icon, Step} from "semantic-ui-react";
import {Page} from "../../../types";
import "./NavigationComponent.css"


export const NavigationComponent = ({page, completed, active, onClick}: NavigationComponentProps) => {

    const disabled = !active && !completed;


    const evaluateTitleColor = ():string => {
        if(active)
            return "color-purple";
        else
            return "color-teal";
    }
    const evaluateIconColor = ():"grey"|"purple" => {
        if(disabled)
            return "grey";
        else return "purple";

    }

    return  <Step completed={completed} active={active} onClick={onClick} disabled={!completed && !active}>
    <Icon className={"step-icon fa-"+page.icon } color={evaluateIconColor()} />
        <Step.Content>
            <Step.Title className={evaluateTitleColor()}>{page.label}</Step.Title>
            <Step.Description>{page.title}</Step.Description>
        </Step.Content>
    </Step>
}
export default NavigationComponent;

interface NavigationComponentProps  {
    page: Page;
    completed: boolean;
    active: boolean;
    onClick: () => void;
}