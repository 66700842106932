import * as React from 'react'
import {Button, Divider} from "semantic-ui-react";
import {useContext} from "react";
import {LanguageContext} from "../../../context";
import "./SurveyControls.css"

export const SurveyControls = ({validateInputs, onNextClick, onPreviousClick, isLastPage, pageID}: SurveyControlsProps) => {

    const {dictionary} = useContext(LanguageContext)

    return (
        <div>
            <Divider fitted className="survey-controls-divider"/>
            <div className="survey-controls-container">
                <Button primary
                        content={isLastPage ? dictionary.completeSurvey : dictionary.nextPage}
                        icon="fa-arrow-right"
                        onClick={() => onNextClick()}
                        size="tiny"
                        compact
                        className="survey-controls-next"
                        disabled={!validateInputs()}
                />

                {pageID > 0 && <Button
                    primary
                    content="Zurück"
                    icon="fa-arrow-left"
                    onClick={() => onPreviousClick()}
                    size="tiny"
                    compact
                    className="survey-controls-previous"
                />}
            </div>
        </div>

    )
}

interface SurveyControlsProps {
    validateInputs: () => boolean;
    onNextClick: () => void;
    onPreviousClick: () => void;
    pageID: number;
    isLastPage: boolean;
}
