import React, {useCallback} from "react";
import {AnswerOption, SelectSurveyElement, SurveyElement} from "../../../types";
import {Form} from "semantic-ui-react";
import {CreateAnswerOptionForm} from "../CreateAnswerOptionForm/CreateAnswerOptionForm";

export const DropdownElementForm = ({element, setElement}: DropdownElementFormProps) => {

   const setOptions = useCallback((options: AnswerOption[]) => {
      setElement({...element, options: options})
   }, [element, setElement])

   return <Form>
      <CreateAnswerOptionForm options={element.options ?? []} setOptions={setOptions}/>
   </Form>
}

type DropdownElementFormProps = {
   element: SelectSurveyElement
   setElement: (element: SurveyElement) => void
}
