import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Grid, GridColumn, GridRow, Header, Icon, Popup, Segment, SegmentGroup} from "semantic-ui-react";
import {getAdminSurveyById} from "../../api/surveys";
import {Page, Survey} from "../../types";
import {CreatePageModal, EditPage, Text} from "../../components";
import "./EditSurveyView.css"
import {containsValues} from "../../util";
import clipboardCopy from "clipboard-copy";
import {EditSurveyModal} from "../../components";

export const EditSurveyView = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [survey, setSurvey] = useState<Survey>({} as Survey)
    const [loading, setLoading] = useState(false)
    const [pageModalOpen, setPageModalOpen] = useState(false)
    const [editingSurvey, setEditingSurvey] = useState(false)
    const [copySuccess, setCopySuccess] = useState(false)


    const handleCopy = () => {
        clipboardCopy(window.location.host + "/survey/"+survey.id).then(
            value => {
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 1000)
            },
            reason => console.log(reason)
        )
    }

    useEffect(() => {
        setLoading(true)
        getAdminSurveyById(id ?? "").then(
            r => {
                setSurvey(r.data)
                setLoading(false)
            },
            reason => {
                if (reason.response.status === 440 || reason.response.status === 401)
                    navigate("/admin/surveys/" + id + "/login/sessionExpired=true")
            }
        )
    }, [navigate, id, setSurvey, setLoading])

    const setPages = useCallback((pages: Page[]) => {
        setSurvey({...survey, pages: pages})
    }, [setSurvey, survey])

    return (
        <Segment basic>
            {!loading && <Grid stackable>
                <GridRow>
                    <GridColumn>
                        <Header as="h1">
                            <Icon color="teal" className={"fa-solid fa-" + survey.icon}/>
                            <Header.Content>
                                {survey.label}
                                <Header.Subheader>
                                    {survey.title}
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <SegmentGroup>
                            <Segment>
                                <div className="survey-info-header">
                                    <Header as="h4">
                                        <Icon className={"fa-solid fa-" + survey.icon}/>
                                        <Header.Content>{survey.label}</Header.Content>
                                    </Header>
                                    <div className="inner">
                                        <Icon size="small" link onClick={() => setEditingSurvey(true)}
                                              className="fa-solid fa-pen"/>
                                        <Popup position="top right" disabled={!copySuccess} content="Copied to clipboard! ☑️" size="mini"
                                               trigger={<Icon size="small" className="fa-solid fa-share" onClick={() => handleCopy()}/>}/>
                                        <EditSurveyModal open={editingSurvey} setOpen={setEditingSurvey} survey={survey} updateSurvey={setSurvey} />
                                    </div>
                                </div>
                            </Segment>
                        </SegmentGroup>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <SegmentGroup>
                            <Segment>
                                <div className="survey-info-header">
                                    <Header as="h4">
                                        <Icon className={"fa-solid fa-comment"}/>
                                        <Header.Content><Text textId={"questions"}/></Header.Content>
                                    </Header>
                                    <div>
                                        <Icon size="small" link onClick={() => setPageModalOpen(true)}
                                              className="fa-solid fa-plus"/>
                                    </div>
                                </div>
                            </Segment>
                            <CreatePageModal open={pageModalOpen} setOpen={setPageModalOpen} surveyId={survey.id}
                                             setPages={setPages}/>
                            <Segment>
                                {containsValues(survey.pages) ? survey.pages.sort((a, b) => a.sortId - b.sortId).map(page => <EditPage
                                    key={page.id} page={page} setPages={setPages} pages={survey.pages}/>) : <Text textId={"surveyWithNoPages"}/>}
                            </Segment>
                        </SegmentGroup>
                    </GridColumn>
                </GridRow>
            </Grid>}
        </Segment>
    )
}