import React, {useCallback, useContext, useState} from "react";
import {Button, Form, FormField, FormGroup, Header, Icon, Input, Modal} from "semantic-ui-react";
import {Page} from "../../../types";
import {Text} from "../../../atomic";
import {LanguageContext} from "../../../context";
import {deletePageById, updatePage, UpdatePageRequest} from "../../../api/pages";
import {useNavigate} from "react-router-dom";

export const EditPageModal = ({open, setOpen, page, setPages}: EditPageModalProps) => {
    
    const [newPage, setNewPage] = useState<UpdatePageRequest>({...page} as UpdatePageRequest)
    const {dictionary} = useContext(LanguageContext)
    const [deleting, setDeleting] = useState(false)
    const [updating, setUpdating] = useState(false)
    const navigate = useNavigate()

    const handleDelete = useCallback(() => {
        setDeleting(true)
        deletePageById(page.id).then(
            value => {
                setTimeout(() => {
                    setPages(value.data)
                    setDeleting(false)
                    setOpen(false)
                }, 200)
            },
            reason => {
                if(reason.response.status === 440) {
                    navigate("/admin/login/sessionExpired=true")
                }
            }
        )
    }, [navigate, setDeleting, page, setPages, setOpen])

    const handleUpdate = useCallback(() => {
        setUpdating(true)
        console.log(newPage)
        updatePage(newPage, page.id).then(
            value => {
                setTimeout(() => {
                    setPages(value.data)
                    setUpdating(false)
                    setOpen(false)
                }, 200)
            },
            reason => {
                if(reason.response.status === 440)
                    navigate("/admin/login/sessionExpired=true")
            }
        )
    }, [setUpdating, newPage, page, setPages, setOpen, navigate])

    return (
        <Modal closeIcon open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
            <Modal.Header>
                <Header>
                    <Icon className="fa-solid fa-comments" color="teal"/>
                    <Header.Content><Text textId={"editPage"}/></Header.Content>
                </Header>
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <FormGroup widths={"equal"}>
                        <FormField>
                            <label><Text textId={"label"}/></label>
                            <Input value={newPage.label}
                                   onChange={((event, data) => setNewPage({...newPage, label: data.value}))}/>
                        </FormField>
                        <FormField width={3}>
                            <label><Text textId={"order"}/></label>
                            <Input value={newPage.sortId} onChange={((event, data) => setNewPage({...page, sortId: Number(data.value)}))}/>
                        </FormField>
                    </FormGroup>

                    <FormField>
                        <label><Text textId={"title"}/></label>
                        <Input value={newPage.title}
                               onChange={((event, data) => setNewPage({...newPage, title: data.value}))}/>
                    </FormField>
                    <FormField>
                        <label><Text textId={"icon"}/></label>
                        <Input value={newPage.icon}
                               onChange={((event, data) => setNewPage({...newPage, icon: data.value}))}/>
                    </FormField>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <div className="edit-survey-actions-wrapper">
                    <Button icon="fa-solid fa-trash" content={dictionary.delete} color="red" loading={deleting}
                            onClick={() => handleDelete()}/>
                    <div>
                        <Button icon="fa-solid fa-times" content={dictionary.cancel} onClick={() => setOpen(false)}/>
                        <Button icon="fa-solid fa-check" content={dictionary.save} color="teal" loading={updating}
                                onClick={() => handleUpdate()}/>
                    </div>
                </div>
            </Modal.Actions> 
        </Modal>
    )
}
type EditPageModalProps = {
    open: boolean
    setOpen: (open: boolean) => void
    page: Page
    setPages: (pages: Page[]) => void
}