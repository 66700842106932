import axios from "axios";
import {makeHttpEndpoint} from "./index";

export const createBlankPage = async(body: CreateBlankPageRequest) => {
    return await axios.post(makeHttpEndpoint("/pages"), body, {withCredentials: true})
}
export const deletePageById = async(id: string) => {
    return await axios.delete(makeHttpEndpoint(`/pages/${id}`), {withCredentials: true})
}
export const updatePage = async(body: UpdatePageRequest, id: string) => {
    console.log(body)
    return await axios.patch(makeHttpEndpoint(`/pages/${id}`), body, {withCredentials: true})
}

export type CreateBlankPageRequest = {
    surveyId: string
    label: string
    title: string
    icon: string
    sortId: number
}
export type UpdatePageRequest = {
    label: string
    title: string
    icon: string
    sortId: number
}