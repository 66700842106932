import React, {useCallback, useContext, useState} from "react";
import {AnswerOption} from "../../../types";
import {
    Button,
    Form,
    FormGroup,
    Header,
    Table,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow
} from "semantic-ui-react";
import "./CreateAnswerOptionForm.css"
import {LanguageContext} from "../../../context";
import {containsValues} from "../../../util";
import {Text} from "../../../atomic";

export const CreateAnswerOptionForm = ({setOptions, options}: CreateAnswerOptionFormProps) => {
    const [newOption, setNewOption] = useState<AnswerOption>({label: "", value: 0})
    const {dictionary} = useContext(LanguageContext)

    const handleAdd = useCallback(() => {
        setOptions([...options, newOption])
        setNewOption({value: 0, label: ""})
    }, [setNewOption, setOptions, options, newOption])

    const handleRemove = useCallback((value: number) => {
        setOptions(options.filter(option => option.value !== value))
    }, [setOptions, options])

    const handleValueChange = useCallback((value: string) => {
        const numValue = Number(value)
        if(numValue === 0 || numValue)
            setNewOption({...newOption, value: numValue})
    }, [setNewOption, newOption])

    return (
        <Form>
            <Header as="h3"><Text textId={"answerOption"}/></Header>
            <FormGroup className="create-answeroption-wrapper">
                <Form.Input value={newOption.label} width={3} label={dictionary.label} onChange={(event, data) => setNewOption({...newOption, label: String(data.value)})}/>
                <Form.Input value={newOption.value} type="number" width={3} label={dictionary.value} onChange={(event, data) => handleValueChange(data.value)}/>
                <Form.Button content={dictionary.addOption} onClick={handleAdd} color={"teal"}/>
            </FormGroup>
            <Table basic="very">
                <TableHeader>
                    <TableHeaderCell width={6}><Text textId={"label"}/></TableHeaderCell>
                    <TableHeaderCell width={6}><Text textId={"value"}/></TableHeaderCell>
                    <TableHeaderCell width={2}/>
                </TableHeader>
                {!containsValues(options) ? <p><Text textId={"noOptions"}/></p> : options.map(option =>
                    <TableRow key={option.value}>
                        <TableCell>{option.label}</TableCell>
                        <TableCell>{option.value}</TableCell>
                        <TableCell><Button onClick={() => handleRemove(option.value)} icon="fa-solid fa-trash" color="red"/></TableCell>
                    </TableRow>
                )}
            </Table>
        </Form>
    )
}

type CreateAnswerOptionFormProps = {
    options: AnswerOption[]
    setOptions: (options: AnswerOption[]) => void
}