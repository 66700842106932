import {Button, Form, FormField, Header, Icon, Input, Message, Segment, SegmentGroup} from "semantic-ui-react";
import * as React from "react";
import {useCallback, useContext, useState} from "react";
import "./CodeVerifier.css"
import {verifySurveyCode, VerifySurveyCodeRequest} from "../../api/surveys";
import {LanguageContext} from "../../context";
import {Text} from "../../atomic";

export const CodeVerifier = ({expected, setVerified}: CodeVerifierProps) => {
    const [code, setCode] = useState<string>("")
    const [wrongCode, setWrongCode] = useState(false)
    const {dictionary} = useContext(LanguageContext)
    const handleSubmit = useCallback(() => {
        verifySurveyCode({expected: expected, actual: code} as VerifySurveyCodeRequest)
            .then(
                value => {
                    if(value.data)
                        setVerified(true)
                    else
                        setWrongCode(true)
                }
            )
    }, [setVerified, setWrongCode, expected, code])
    return (
        <Form id="home" onSubmit={handleSubmit} error={wrongCode}>
            <SegmentGroup className="login">
                <Segment>
                    <Header as="h3">
                        <Icon className="fa-solid fa-user-astronaut" color="teal"/>
                        <Header.Content>
                           <Text textId={"welcome"}/>
                            <Header.Subheader>
                                <Text textId={"enterSurveyCode"}/>
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                </Segment>
                <Segment>
                    <FormField className="login-input">
                        <label>Code</label>
                        <Input placeholder="Code" onChange={(event, data) => setCode(data.value)}/>
                    </FormField>
                    <Message error header={dictionary.wrongSurveyCodeHeader} content={dictionary.wrongSurveyCodeContent}/>
                </Segment>
                <Segment className="login-controls">
                    <Button type="submit" color="teal" content="Zur Umfrage!" icon="fa-sign-in" size="tiny" compact/>
                </Segment>
            </SegmentGroup>
        </Form>
    )
}
export type CodeVerifierProps = {
    setVerified: (b: boolean) => void
    expected: string
}