/**
 * @copyright This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

import * as React from "react";
const marked = require("marked")


interface MarkdownProps {
    source: string;
}

export const Markdown = (props: MarkdownProps) => {
    if (props.source == null || props.source === "")
        return null;

    const html = marked(props.source);

    return (
        <div
            dangerouslySetInnerHTML={{ __html: html }}
            className="Markdown"
          />
    );
};