import React, {useState} from "react";
import {SurveyElement} from "../../../types";
import {Button, Table} from "semantic-ui-react";
import {CreateElementModal} from "../CreateElementModal/CreateElementModal";

export const SurveyElementRow = ({element, setElements, pageId}: SurveyElementRowProps) => {

    const [open, setOpen] = useState(false)

    return (
        <Table.Row>

            <Table.Cell width={6}>
                {element.label}
            </Table.Cell>

            <Table.Cell width={6}>
                {element.type}
            </Table.Cell>
            <Table.Cell width={1}>
                {element.sortId}
            </Table.Cell>

            <Table.Cell textAlign="right" verticalAlign="bottom">
                <Button size="tiny" compact basic icon="fa-solid fa-pen" onClick={() => setOpen(true)}/>
            </Table.Cell>
            <CreateElementModal open={open} setOpen={setOpen} setElements={setElements} pageId={pageId} update={true}
                                element={element}/>
        </Table.Row>
    )
}

type SurveyElementRowProps = {
    element: SurveyElement
    setElements: (elements: SurveyElement[]) => void;
    pageId: string
}