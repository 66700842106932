import React from "react";
import {InputSurveyElement, SurveyAnswer} from "../../../types";
import {FormField, Input} from "semantic-ui-react";

export const InputSurveyComponent = ({element, pushAnswer, getAnswer}: InputSurveyComponentProps) => {

    const onChange = (value: string, questionId: string) => {
        pushAnswer({questionId: questionId, textValue: value} as SurveyAnswer)
    }


    return (
        <div>
            {element.questions.map(question => {
                return (
                    <FormField key={question.id}>
                        <label>{question.label}</label>
                        <Input placeholder={question.label} onChange={(event => onChange(event.target.value, question.id))} defaultValue={getAnswer(question.id).textValue ?? ""}/>
                    </FormField>
                )
            })}

        </div>
    )
}
export default InputSurveyComponent;

interface InputSurveyComponentProps {
    element: InputSurveyElement;
    pushAnswer: (answer: SurveyAnswer) => void;
    getAnswer: (questionId: string) => SurveyAnswer;
}