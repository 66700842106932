import React, {useCallback, useState} from "react";
import {Button, Popup, Table} from "semantic-ui-react";
import {Survey} from "../../../types";
import clipboardCopy from "clipboard-copy";
import {SuperAdminEditSurveyModal} from "../../../components";
import {getAnswerSummaryForSurvey} from "../../../api/answersummary";
import {useNavigate} from "react-router-dom";
import FileSaver from 'file-saver';
import {exportToCsvFile} from "../../../util/CsvUtil";
import {getAnswerOptionsForSurvey} from "../../../api/answeroption";

const answersummary_header = "answer_id;participation_id;date_completed;answer_value;question_key;question_label;type;element_id;element_sort_id;element_required;element_label;element_text;element_max_rate_label;element_min_rate_label;element_step;page_id;page_label;page_title;survey_label;survey_title;survey_id;survey_created_at"
const answeroption_header = "element_id;answeroption_label;answeroption_value";
export const SurveyRow = ({survey, setSurveys}: SurveyRowProps) => {

    const navigate = useNavigate()
    const [copySuccess, setCopySuccess] = useState(false)
    const [editing, setEditing] = useState(false)

    const handleCopy = () => {
        clipboardCopy(window.location.host+ "/admin/surveys/" + survey.id).then(
            _ => {
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 1000)
            },
            reason => console.log(reason)
        )
    }

    const handleCsvDownload = useCallback(() => {
        getAnswerSummaryForSurvey(survey.id).then(
            value => {FileSaver.saveAs(exportToCsvFile(value.data, answersummary_header), `${survey.label}_results.csv`)},
            error => {
                if(error.response.status === 440)
                    navigate("/admin/login/sessionExpired=true")
                else alert("Failed to download CSV: " + error.response.message)
            }
        )
        getAnswerOptionsForSurvey(survey.id).then(
            value => {FileSaver.saveAs(exportToCsvFile(value.data, answeroption_header), `${survey.label}_answeroptions.csv`)},
            error => {
                if(error.response.status === 440)
                    navigate("/admin/login/sessionExpired=true")
                else alert("Failed to download Answer Options CSV: " + error.response.message)
            }
        )
    }, [navigate, survey])

    return <Table.Row>
        <Table.Cell>{survey.label}</Table.Cell>
        <Table.Cell textAlign="right">{new Date(survey.createdAt.toString()).toLocaleString()}</Table.Cell>
        <Table.Cell textAlign="right">
            <Button icon="fa-solid fa-pen" onClick={() => setEditing(true)}/>
            <Button icon="fa-file-csv" onClick={handleCsvDownload}/>
            <Popup position="top right" disabled={!copySuccess} content="Copied to clipboard! ☑️" size="mini"
                   trigger={<Button icon="fa-solid fa-share" onClick={() => handleCopy()}/>}/>

        </Table.Cell>
        <SuperAdminEditSurveyModal setSurveys={setSurveys} open={editing} setOpen={setEditing} survey={survey}/>
    </Table.Row>
}
type SurveyRowProps = {
    survey: Survey
    setSurveys: (surveys: Survey[]) => void
}