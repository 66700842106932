import {SurveyAnswer} from "../types";
import axios from "axios";
import {makeHttpEndpoint} from "./index";

export const postParticipationWithAnswers = async(body: CreateParticipationAnswersBody) => {
    const res = await axios.post(makeHttpEndpoint("/participations"), body)
    return res.data
}
export type CreateParticipationAnswersBody = {
    answers: SurveyAnswer[]
}