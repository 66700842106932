import * as React from 'react'
import {Image} from "semantic-ui-react";
import {LanguageSelector} from "../LanguageSelector/LanguageSelector";
import "./Menubar.css"

export const Menubar = () => {

    return <div className={"menubar"}>
        <Image
            className="menubar-logo"
            src={"/images/dataspot.svg"}/>
        <LanguageSelector/>
    </div>
}