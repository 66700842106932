import React, {useCallback, useContext, useState} from "react";
import {RatingSurveyElement, ScaleSurveyElement, SelectSurveyElement, SurveyElement} from "../../../types";
import {
    Button, Checkbox,
    Dropdown,
    Form,
    FormField,
    FormGroup,
    Header,
    Icon,
    Input,
    Message,
    Modal,
    TextArea
} from "semantic-ui-react";
import {Text} from "../../../atomic";
import {contains} from "../../../util";
import {LanguageContext} from "../../../context";
import {RatingElementForm} from "../RatingElementForm/RatingElementForm";
import {deleteSurveyElement, postSurveyElement, PutElementBody, putSurveyElement} from "../../../api/elements";
import {CreateQuestionForm} from "../CreateQuestionForm/CreateQuestionForm";
import {Question} from "../../../types/Question";
import {DropdownElementForm} from "../DropdownElementForm/DropdownElementForm";
import {ScaleElementForm} from "../ScaleElementForm/ScaleElementForm";

const elementOptions = [
    {key: 1, text: "Rating", value: "RATING"},
    {key: 2, text: "Freetext", value: "FREETEXT"},
    {key: 3, text: "Dropdown", value: "DROPDOWN"},
    {key: 4, text: "Multiple Choice", value: "MULTIPLE"},
    {key: 5, text: "Scale", value: "SCALE"},
    {key: 6, text: "None", value: "NONE"},
]
export const CreateElementModal = ({open, setOpen, setElements, pageId, element, update}: CreateElementModalProps) => {

    const [newElement, setNewElement] = useState<SurveyElement>(update ? (element ?? {} as SurveyElement) : {} as SurveyElement)
    const [errors] = useState<string[]>([])
    const [creating, setCreating] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const {dictionary} = useContext(LanguageContext)


    const handleCreate = useCallback(() => {
        setCreating(true)
        const questions = newElement.questions ?? [{label: ""}]
        postSurveyElement({element: {...newElement, questions}, pageId: pageId}).then(
            value => {
                setTimeout(() => {
                    console.log(value.data)
                    setElements(value.data)
                    setCreating(false)
                    setOpen(false)
                    setNewElement({} as SurveyElement)
                }, 200)
            },
            reason => console.log(reason)
        )
    }, [newElement, pageId, setElements, setOpen])

    const handleUpdate = useCallback(() => {
        setCreating(true)
        const questions = newElement.questions ?? [{label: ""}]
        putSurveyElement({
            element: {...newElement, questions: questions},
            pageId: pageId
        } as PutElementBody, element?.id ?? "").then(
            value => {
                setTimeout(() => {
                    console.log(value.data)
                    setElements(value.data)
                    setCreating(false)
                    setOpen(false)
                }, 200)
            }
        )
    }, [newElement, pageId, setElements, setOpen, element])


    const handleSubmit = useCallback(() => {
        if (update)
            handleUpdate()
        else
            handleCreate()
    }, [update, handleCreate, handleUpdate])

    const handleDelete = useCallback(() => {
        setDeleting(true)
        deleteSurveyElement(element?.id ?? "").then(
            value => {
                setTimeout(() => {
                    console.log(value.data)
                    setElements(value.data)
                    setDeleting(false)
                    setOpen(false)
                })
            }
        )
    }, [setDeleting, setElements, setOpen, element])

    const setRateLabel = useCallback((min: boolean, value: string) => {
        let element = {...newElement} as RatingSurveyElement
        if (min)
            element.minRateLabel = value
        else
            element.maxRateLabel = value
        setNewElement(element)
    }, [setNewElement, newElement])

    const setStep = useCallback((step: number) => {
        let element = {...newElement} as ScaleSurveyElement
        element.step = step
        setNewElement(element)
    }, [newElement, setNewElement])

    const renderTypeForm = (type: string) => {
        switch (type) {
            case "RATING":
                return <RatingElementForm element={{...newElement} as RatingSurveyElement} setElement={setNewElement}
                                          setRateLabel={setRateLabel}/>
            case "DROPDOWN":
                return <DropdownElementForm element={{...newElement} as SelectSurveyElement}
                                            setElement={setNewElement}/>
            case "SCALE":
                return <ScaleElementForm element={{...newElement} as ScaleSurveyElement} setElement={setNewElement}
                                         setStep={setStep}/>
        }
    }

    const setQuestions = useCallback((questions: Question[]) => {
        setNewElement({...newElement, questions: questions})
    }, [newElement, setNewElement])


    return (
        <Modal closeIcon open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
            <Modal.Header>
                <Header>
                    <Icon className="fa-solid fa-question" color="teal"/>
                    <Header.Content>
                        <Text textId={"createNewElement"}/>
                    </Header.Content>
                </Header>
            </Modal.Header>
            <Modal.Content>
                <Form error={errors.length !== 0}>
                    <FormGroup widths="equal">
                        <FormField width={4} error={contains(errors, "label")}>
                            <label><Text textId={"label"}/></label>
                            <Input value={newElement.label}
                                   onChange={((event, data) => setNewElement({...newElement, label: data.value}))}/>
                        </FormField>
                        <FormField width={4} error={contains(errors, "type")}>
                            <label><Text textId={"elementType"}/></label>
                            <Dropdown value={newElement.type} selection options={elementOptions}
                                      onChange={(event, data) => setNewElement({
                                          ...newElement,
                                          type: String(data.value)
                                      })}/>
                        </FormField>
                        <FormField width={1}>
                            <label><Text textId={"order"}/></label>
                            <Input value={newElement.sortId}
                                   onChange={((event, data) => setNewElement({
                                       ...newElement,
                                       sortId: Number(data.value)
                                   }))}/>
                        </FormField>
                    </FormGroup>
                    <FormField>
                        <Checkbox label={dictionary.required} checked={newElement.required ?? false}
                                  onChange={(event, data) => setNewElement({...newElement, required: data.checked ?? false})}/>
                    </FormField>
                    <FormField error={contains(errors, "description")}>
                        <label><Text textId={"description"}/></label>
                        <TextArea value={newElement.text}
                                  onChange={(event, data) => setNewElement({...newElement, text: String(data.value)})}/>
                    </FormField>
                    {renderTypeForm(newElement.type)}
                    {newElement.type !== "NONE" && <CreateQuestionForm setQuestions={setQuestions} questions={newElement.questions ?? []}/>}
                    <Message error>
                        <Message.Header><Text textId={"fillOutAllFieldsHeader"}/></Message.Header>
                    </Message>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {update && <Button icon="fa-solid fa-trash" content={dictionary.delete} color="red" loading={deleting}
                                   onClick={() => handleDelete()}/>}
                <Button icon="fa-solid fa-times" content={dictionary.cancel} onClick={() => setOpen(false)}/>
                <Button icon="fa-solid fa-check" content={update ? dictionary.save : dictionary.create} color="teal"
                        loading={creating}
                        onClick={() => handleSubmit()}/>
            </Modal.Actions>
        </Modal>
    )
}

type CreateElementModalProps = {
    open: boolean
    setOpen: (open: boolean) => void
    setElements: (elements: SurveyElement[]) => void
    pageId: string
    element?: SurveyElement
    update: boolean
}
