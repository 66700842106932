import React from "react";
import {Button} from "semantic-ui-react";
import {RatingSurveyElement, SurveyAnswer} from "../../../types";
import "./RatingSurveyComponent.css"

export const RatingSurveyComponent = ({element, pushAnswer, getAnswer}: RatingSurveyComponentProps) => {


    let min = Number.POSITIVE_INFINITY
    let max = Number.NEGATIVE_INFINITY
    for (let option of element.options) {
        if (option.value > max)
            max = option.value
        if (option.value < min)
            min = option.value
    }

    const onClick = (value: number, questionId: string) => {
        pushAnswer({questionId: questionId, numberValue: value} as SurveyAnswer)
    }

    return (
        <div>
            <p>{min} ... {element.minRateLabel}</p>
            <p>{max} ... {element.maxRateLabel}</p>
            <br/>
            <br/>
            {element.questions.map(question => {
                return (
                    <div key={question.id}>
                        <Button.Group className="rating-question">
                            {element.options.map(option => {
                                return (
                                    <Button size="small" active={option.value === getAnswer(question.id).numberValue}
                                            onClick={() => onClick(option.value, question.id)}
                                            compact key={option.value}>{option.label}</Button>
                                )
                            })}
                        </Button.Group>
                        <label>{question.label}</label>
                        <br/>
                        <br/>
                    </div>
                )
            })}
        </div>
    )
}

export default RatingSurveyComponent;

interface RatingSurveyComponentProps {
    element: RatingSurveyElement;
    pushAnswer: (answer: SurveyAnswer) => void;
    getAnswer: (questionId: string) => SurveyAnswer;
}