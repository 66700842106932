import * as React from 'react';
import {ScaleSurveyElement, SurveyAnswer} from "../../../types";
import Slider from "react-rangeslider";
import "./ScaleSurveyComponent.css"

export const ScaleSurveyComponent = ({element, getAnswer, pushAnswer}: ScaleSurveyComponentProps) => {

    const format = (value: number) => Math.round(value * 10) / 10
    const handle = (value: number, questionId: string) => {
        const roundedValue = Math.round(value * 10) / 10
        pushAnswer({questionId: questionId, numberValue: roundedValue} as SurveyAnswer)
    }

    let min = Number.POSITIVE_INFINITY
    let max = Number.NEGATIVE_INFINITY
    for (let option of element.options) {
        if(option.value > max)
            max = option.value
        if(option.value < min)
            min = option.value
    }

    const generateLabels = () => {
        const labels: { [value: number]: string } = {}
        element.options.forEach(it => {
            labels[it.value] = String(it.value)
        })
        return labels
    }


    return (
        <div className="scale-container">
            {element.options.map(option => {
                return (
                    <p key={option.value}>{option.value} ... {option.label}</p>
                )
            })}
            {element.questions.map(question => {
                const value = getAnswer(question.id).numberValue ?? 0
                return (
                    <div className="scale-slider-wrapper" key={question.id}>
                        <Slider format={format}  min={min} max={max} step={element.step} onChange={(value) => handle(value, question.id)}
                           className={"scale-slider"} value={value} labels={generateLabels()}/>
                        <label className="scale-label">{question.label}</label>
                    </div>
                )
            })}
        </div>


    )
}

export default ScaleSurveyComponent;


interface ScaleSurveyComponentProps {
    element: ScaleSurveyElement;
    pushAnswer: (answer: SurveyAnswer) => void;
    getAnswer: (questionId: string) => SurveyAnswer;
}
