import {Button, Form, FormField, FormGroup, Header, Icon, Input, Message, Modal} from "semantic-ui-react";
import {Text} from "../../../atomic";
import React, {useCallback, useContext, useState} from "react";
import {createBlankPage, CreateBlankPageRequest} from "../../../api/pages";
import {LanguageContext} from "../../../context";
import {contains} from "../../../util";
import {useNavigate} from "react-router-dom";
import {Page} from "../../../types";

export const CreatePageModal = ({open, setOpen, surveyId, setPages}: CreatePageModalProps) => {

    const [page, setPage] = useState<CreateBlankPageRequest>({} as CreateBlankPageRequest)
    const [errors, setErrors] = useState<string[]>([])
    const navigate = useNavigate()
    const [creating, setCreating] = useState(false)
    const {dictionary} = useContext(LanguageContext)

    const validate = useCallback((): boolean => {
        const newErrors: string[] = []
        let valid = true;
        let label = page.label !== undefined && page.label !== null && page.label !== ""
        if (!label) {
            newErrors.push("label")
            valid = false;
        }
        let title = page.title !== undefined && page.title !== null && page.title !== ""
        if (!title) {
            newErrors.push("title")
            valid = false;
        }
        let icon = page.icon !== undefined && page.icon !== null && page.icon !== ""
        if (!icon) {
            newErrors.push("icon")
            valid = false;
        }
        setErrors(newErrors)
        return valid;
    }, [setErrors, page])


    const handleCreate = useCallback(() => {
        if (validate()) {
            setCreating(true)
            createBlankPage({...page, surveyId: surveyId}).then(
                value => {
                    setTimeout(() => {
                        setPages(value.data)
                        setCreating(false)
                        setOpen(false)
                        setPage({} as CreateBlankPageRequest)
                    }, 200)
                },
                reason => {
                    if(reason.response.status === 440)
                        navigate("/admin/login/sessionExpired=true")
                }
            )
        }
    }, [validate, page, surveyId, setPages, setOpen, navigate])


    return (
        <Modal closeIcon open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
            <Modal.Header>
                <Header>
                    <Icon className="fa-solid fa-list" color="teal"/>
                    <Header.Content>
                        <Text textId={"createNewPage"}/>
                    </Header.Content>
                </Header>
            </Modal.Header>
            <Modal.Content>
                <Form error={errors.length !== 0}>
                    <FormGroup widths={"equal"}>
                        <FormField error={contains(errors, "label")}>
                            <label><Text textId={"label"}/></label>
                            <Input onChange={((event, data) => setPage({...page, label: data.value}))}/>
                        </FormField>
                        <FormField width={3}>
                            <label><Text textId={"order"}/></label>
                            <Input onChange={((event, data) => setPage({...page, sortId: Number(data.value)}))}/>
                        </FormField>
                    </FormGroup>
                    <FormField error={contains(errors, "title")}>
                        <label><Text textId={"title"}/></label>
                        <Input onChange={((event, data) => setPage({...page, title: data.value}))}/>
                    </FormField>
                    <FormField error={contains(errors, "icon")}>
                        <label><Text textId={"icon"}/></label>
                        <Input onChange={((event, data) => setPage({...page, icon: data.value}))}/>
                    </FormField>
                    <Message error>
                        <Message.Header><Text textId={"fillOutAllFieldsHeader"}/></Message.Header>
                    </Message>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button icon="fa-solid fa-times" content={dictionary.cancel} onClick={() => setOpen(false)}/>
                <Button icon="fa-solid fa-check" content={dictionary.create} color="teal" loading={creating}
                        onClick={() => handleCreate()}/>
            </Modal.Actions>
        </Modal>
    )
}

type CreatePageModalProps = {
    open: boolean
    setOpen: (b: boolean) => void
    surveyId: string
    setPages: (pages: Page[]) => void
}
