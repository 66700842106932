import * as React from 'react'
import {Segment} from "semantic-ui-react";
import "./NotFound.css";

export const NotFound = () => {

    return <Segment basic className={"notfound"}>
        <h1>Die von Ihnen angeforderte Umfrage existiert nicht.</h1>
    </Segment>

}