import React from "react";
import {Grid, GridColumn, GridRow, Header, Icon, Segment} from "semantic-ui-react";
import {SurveyOverview} from "../../components";
import {Text} from "../../atomic";

export const AdminDashboard = () => {

    return (
        <Segment basic>
            <Grid>
                <GridRow>
                    <GridColumn>
                        <Header as="h1">
                            <Icon className="fa-solid fa-cogs" color="teal"/>
                            <Header.Content>
                                <Text textId={"surveyOverview"}/>
                                <Header.Subheader>
                                    <Text textId={"editSurveys"}/>
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <SurveyOverview/>
                    </GridColumn>
                </GridRow>
            </Grid>
        </Segment>

    )
}