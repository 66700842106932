import React, {useCallback, useContext} from "react";
import {AnswerOption, RatingSurveyElement, SurveyElement} from "../../../types";
import {Form, FormGroup} from "semantic-ui-react";
import {CreateAnswerOptionForm} from "../CreateAnswerOptionForm/CreateAnswerOptionForm";
import {LanguageContext} from "../../../context";

export const RatingElementForm = ({setElement, element, setRateLabel}: RatingElementFormProps) => {

    const {dictionary} = useContext(LanguageContext)

    const setOptions = useCallback((options: AnswerOption[]) => {
        setElement({...element, options: options})
    }, [element, setElement])

    return (
        <div>
            <FormGroup>
                <Form.Input value={element.minRateLabel} width={3} label={dictionary.minRateLabel}
                            onChange={(event, data) => setRateLabel(true, String(data.value))}/>
                <Form.Input value={element.maxRateLabel} width={3} label={dictionary.maxRateLabel}
                            onChange={(event, data) => setRateLabel(false, String(data.value))}/>
            </FormGroup>
            <CreateAnswerOptionForm setOptions={setOptions} options={element.options ?? []}/>
        </div>
    )
}

type RatingElementFormProps = {
    setElement: (element: SurveyElement) => void;
    element: RatingSurveyElement;
    setRateLabel: (min: boolean, value: string) => void;
}