import {makeHttpEndpoint} from "./index";
import axios, {AxiosResponse} from "axios";

export const addSuperAdminHeader = (pass: string) => {
    return {
        auth: {
            username: "superadmin",
            password: pass
        },
        withCredentials: true
    }
}

export const addAdminHeader = (pass: string) => {
    return {
        auth: {
            username: "admin",
            password: pass
        },
        withCredentials: true
    }
}

export const authenticate = (pass: string): Promise<AxiosResponse> => {
    return axios.get(makeHttpEndpoint("/auth"), addSuperAdminHeader(pass))
}
export const authenticateSurveyAdmin = (id: string, pass: string) => {
    return axios.get(makeHttpEndpoint(`/auth/survey/${id}`), addAdminHeader(pass))
}