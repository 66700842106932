import React, {useEffect, useState} from "react";
import {Button, Header, Icon, Segment, SegmentGroup, Table} from "semantic-ui-react";
import {Text} from "../../../atomic";
import {Survey} from "../../../types";
import {useNavigate} from "react-router-dom";
import {getAllSurveys} from "../../../api/surveys";
import {SurveyRow, CreateSurveyModal} from "../../../components";

export const SurveyOverview = () => {

    const [surveys, setSurveys] = useState<Survey[]>([])
    const [modalOpen, setModalOpen] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        getAllSurveys().then(
            value => {
                setSurveys(value.data)
            },
            reason => {
                if (reason.response.status === 440 || reason.response.status === 401)
                    navigate("/admin/login/sessionExpired=true")
            }
        )
    }, [setSurveys, navigate])

    return <SegmentGroup>
        <Segment>
            <Header as="h3">
                <Icon className="fa-solid fa-comments" color="teal"/>
                <Header.Content>
                    <Text textId={"surveys"}/>
                </Header.Content>
            </Header>
        </Segment>
        <Segment>
            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={8}><Text textId={"surveys"}/></Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" width={3}><Text textId={"createdAt"}/></Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" width={2}><Button onClick={() => setModalOpen(true)} basic
                                                                              size="small"
                                                                              icon="fa-plus"/></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {surveys.map(survey => {
                        return <SurveyRow key={survey.id} survey={survey} setSurveys={setSurveys}/>
                    })}
                </Table.Body>
            </Table>
        </Segment>
        <CreateSurveyModal setSurveys={setSurveys} open={modalOpen} setOpen={setModalOpen} surveys={surveys}/>
    </SegmentGroup>
}
