import {Segment} from "semantic-ui-react";
import React from "react";
import {Text} from "../../atomic";
import "./Success.css"


export const Success = () => {

    return <Segment basic className="success">
        <h1><Text textId="successSubmit"/></h1>
    </Segment>
}