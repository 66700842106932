import React from "react";
import {StepGroup} from "semantic-ui-react";
import {Page} from "../../../types";
import "./NavigationBar.css"
import {NavigationComponent} from "../../../components";
import {contains} from "../../../util";


export const NavigationBar = ({pages, completedElements, activeElement, onStepClick}: NavigationBarProps) => {

    const children: any[] = [];


    const createNavigationBar = () => {
        for(let i=0;i<pages.length;i++){
            children.push(<NavigationComponent page={pages[i]} key={i} completed={contains(completedElements, i)} active={i === activeElement} onClick={() => onStepClick(i)}/>)
        }

    }
    createNavigationBar();
        return  <StepGroup vertical className={"step-navigation-bar"}>
        {children}
    </StepGroup>

}
interface NavigationBarProps {
    pages: Page[];
    completedElements: number[];
    activeElement: number;
    onStepClick: (i: number) => void;
}

