import React, {useCallback, useState} from "react";
import {Header, Icon, Table} from "semantic-ui-react";
import {Page, SurveyElement} from "../../../types";
import {SurveyElementRow} from "../SurveyElementRow/SurveyElementRow";
import {containsValues} from "../../../util";
import {Text} from "../../../atomic";
import {EditPageModal} from "../EditPageModal/EditPageModal";
import {CreateElementModal} from "../CreateElementModal/CreateElementModal";

export const EditPage = ({page, setPages, pages}: EditPageProps) => {

    const [editModalOpen, setEditModalOpen] = useState(false)
    const [createModalOpen, setCreateModalOpen] = useState(false)

    const setElements = useCallback((elements: SurveyElement[]) => {
        setPages([...pages.filter(p => p.id !== page.id), {...page, elements: elements}])
    }, [setPages, page, pages])

    return (
        <Table basic="very">
            <Table.Header>
                <Table.HeaderCell width={6}>
                    <Header as="h4">
                        <Icon className={"fa-solid fa-" + page.icon}/>
                        <Header.Content>
                            {page.label}
                            <Header.Subheader>
                                {page.title}
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width={6}/>
                <Table.HeaderCell width={1}>{page.sortId}</Table.HeaderCell>
                <Table.HeaderCell textAlign="right" verticalAlign="bottom">
                    <Icon link size="small" className="fa-solid fa-pen" onClick={() => setEditModalOpen(true)}/>
                    <Icon link size="small" className="fa-solid fa-plus" onClick={() => setCreateModalOpen(true)}/>
                </Table.HeaderCell>
            </Table.Header>
            {containsValues(page.elements) ? page.elements.sort((a, b) => a.sortId - b.sortId).map(element => <SurveyElementRow pageId={page.id} setElements={setElements} element={element}/>) : <Text textId={"pageWithNoElements"}/>}
            <EditPageModal open={editModalOpen} setOpen={setEditModalOpen} page={page} setPages={setPages}/>
            <CreateElementModal open={createModalOpen} setOpen={setCreateModalOpen} setElements={setElements} pageId={page.id} update={false}/>
        </Table>
    )
}

type EditPageProps = {
    page: Page
    setPages: (pages: Page[]) => void
    pages: Page[]
}
