import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import {Menubar} from "./components";
import {
    SurveyComponent,
    NotFound,
    SuperAdminLogin,
    AdminDashboard,
    EditSurveyView,
    SurveyAdminLogin,
    Success
} from "./views";
import {LanguageProvider} from "./context";



function App() {

    return <LanguageProvider>
        <div className="App">
            <Menubar/>
            <Routes>
                <Route path="/survey/:id" element={<SurveyComponent/>}/>
                <Route path="/admin/surveys" element={<AdminDashboard/>}/>
                <Route path="/admin/login" element={<SuperAdminLogin/>}/>
                <Route path="/admin/surveys/:id" element={<EditSurveyView/>}/>
                <Route path="/admin/surveys/:id/login" element={<SurveyAdminLogin/>}/>
                <Route path="/admin/surveys/:id/login/sessionExpired=:expired" element={<SurveyAdminLogin/>}/>
                <Route path="/admin/login/sessionExpired=:expired" element={<SuperAdminLogin/>}/>
                <Route path="/admin" element={<Navigate replace to="/admin/surveys"/>}/>
                <Route path="success" element={<Success/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </div>
    </LanguageProvider>

}

export default App;

