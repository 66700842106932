import axios, {AxiosResponse} from "axios";
import {makeHttpEndpoint} from "./index";
import {Survey} from "../types";


export const getSurveyById = async(id: string) => {
    return await axios.get(makeHttpEndpoint(`/surveys/${id}`))
}
export const getAdminSurveyById = async(id: string) => {
    return await axios.get(makeHttpEndpoint(`/surveys/admin/${id}`), {withCredentials: true})
}
export const getAllSurveys = async(): Promise<AxiosResponse<Survey[]>> => {
    return await axios.get(makeHttpEndpoint("/surveys"), {withCredentials: true})
}

export const createBlankSurvey = async(body: CreateBlankSurveyBody): Promise<AxiosResponse<Survey[]>> => {
    return await axios.post(makeHttpEndpoint("/surveys/blank"), body, {withCredentials: true})
}

export const createSurveyFromTemplate = async(body: CreateSurveyFromTemplateBody): Promise<AxiosResponse<Survey[]>> => {
    return await axios.post(makeHttpEndpoint("/surveys/template"), body, {withCredentials: true})
}

export const verifySurveyCode = async(body: VerifySurveyCodeRequest) => {
    return await axios.post(makeHttpEndpoint("/surveys/verify"), body)
}

export const deleteSurveyById = async(id: string) => {
    return await axios.delete(makeHttpEndpoint(`/surveys/${id}`), {withCredentials: true});
}

export const partiallyUpdateSurveyById = async(id: string, body: PartiallyUpdateSurveyRequest) => {
    return await axios.patch(makeHttpEndpoint(`/surveys/${id}`), body, {withCredentials: true})
}

export type CreateBlankSurveyBody = {
    label: string;
    title: string;
    icon: string;
    adminCode: string;
}
export type CreateSurveyFromTemplateBody = {
    label: string;
    title: string;
    icon: string;
    adminCode: string;
    template: Survey;
}
export type VerifySurveyCodeRequest = {
    expected: string;
    actual: string;
}
export type PartiallyUpdateSurveyRequest = {
    label: string
    title: string
    icon: string
    adminCode: string
    accessCode: string
}