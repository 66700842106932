import React, {FC, useState} from "react";

import de from "../languages/de.json"
import en from "../languages/en.json"

export const dictionaryList: DictionaryList = {
    en: en,
    de: de
}

export const languageOptions: LanguageOptions = {
    en: "English",
    de: "Deutsch"
}

export const LanguageContext = React.createContext({
    language: "en",
    updateLanguage: (language: string) => {},
    dictionary: dictionaryList.en as Dictionary
})

export const LanguageProvider: FC = ({children}) => {
    const [userLanguage, setUserLanguage] = useState(window.localStorage.getItem("dxsurvey-lang") ?? "en")

    const value = {
        language: userLanguage,
        dictionary: dictionaryList[userLanguage],
        updateLanguage: (language: string) => {
            const newLang = languageOptions[language] ? language : "en"
            setUserLanguage(newLang)
            window.localStorage.setItem("dxsurvey-lang", newLang)
        }
    }
    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    )

}
type LanguageOptions = {
    [index: string]: string
}
type DictionaryList = {
    [index: string]: {}
}
type Dictionary = {
    [index: string]: string
}