import React, {useCallback, useContext, useState} from "react";
import {Button, Form, FormField, Header, Icon, Input, Modal} from "semantic-ui-react";
import {Text} from "../../../atomic";
import {
    partiallyUpdateSurveyById,
    PartiallyUpdateSurveyRequest
} from "../../../api/surveys";
import {LanguageContext} from "../../../context";
import "./EditSurveyModal.css"
import {Survey} from "../../../types";

export const EditSurveyModal = ({open, setOpen, survey, updateSurvey}: EditSurveyModalProps) => {

    const [newSurvey, setNewSurvey] = useState({...survey, accessCode: ""} as PartiallyUpdateSurveyRequest)
    const [updating, setUpdating] = useState(false)
    const {dictionary} = useContext(LanguageContext)


    const handleUpdate = useCallback(() => {
        setUpdating(true)
        partiallyUpdateSurveyById(survey.id, newSurvey).then(
            value => {
                setTimeout(() => {
                    updateSurvey(value.data.filter((s: Survey) => s.id === survey.id)[0])
                    setUpdating(false)
                    setOpen(false)
                }, 200)
            },
            reason => console.log(reason)
        )
    }, [setUpdating, newSurvey, setOpen, survey, updateSurvey])

    return (
        <Modal closeIcon open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
            <Modal.Header>
                <Header>
                    <Icon className="fa-solid fa-comments" color="teal"/>
                    <Header.Content><Text textId={"editSurvey"}/></Header.Content>
                </Header>
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <FormField>
                        <label><Text textId={"label"}/></label>
                        <Input value={newSurvey.label}
                               onChange={((event, data) => setNewSurvey({...newSurvey, label: data.value}))}/>
                    </FormField>
                    <FormField>
                        <label><Text textId={"title"}/></label>
                        <Input value={newSurvey.title}
                               onChange={((event, data) => setNewSurvey({...newSurvey, title: data.value}))}/>
                    </FormField>
                    <FormField>
                        <label><Text textId={"icon"}/></label>
                        <Input value={newSurvey.icon}
                               onChange={((event, data) => setNewSurvey({...newSurvey, icon: data.value}))}/>
                    </FormField>
                    <FormField>
                        <label><Text textId={"accessPassword"}/></label>
                        <Input value={newSurvey.accessCode}
                               onChange={((event, data) => setNewSurvey({...newSurvey, accessCode: data.value}))}/>
                    </FormField>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <div className="edit-survey-actions-wrapper">
                    <Button icon="fa-solid fa-times" content={dictionary.cancel} onClick={() => setOpen(false)}/>
                    <Button icon="fa-solid fa-check" content={dictionary.save} color="teal" loading={updating}
                            onClick={() => handleUpdate()}/>
                </div>
            </Modal.Actions>
        </Modal>
    )
}

type EditSurveyModalProps = {
    open: boolean
    setOpen: (b: boolean) => void
    survey: Survey
    updateSurvey: (survey: Survey) => void
}
