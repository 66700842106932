import React from 'react'
import {Dropdown, FormField} from "semantic-ui-react";
import {AnswerOption, SelectOption, SurveyAnswer} from "../../../types";
import {SelectSurveyElement} from "../../../types";

export const DropdownSurveyComponent = ({element, pushAnswer, getAnswer}: SelectAnswerOptionProps) => {
    const selectableOptions: SelectOption[] = [];

    element.options.forEach(({label, value}: AnswerOption) => {
        let selectOption: SelectOption = {} as SelectOption;
        selectOption.text = label;
        selectOption.value = value;
        selectOption.key = String(value);
        selectableOptions.push(selectOption);
    });


    const onChange = (value: number, questionId: string) => {
        pushAnswer({questionId: questionId, numberValue: value} as SurveyAnswer)
    }

    return (
        <div>
            {element.questions.map(question => {
                return (
                    <FormField key={question.id} width={"six"} >
                        <label>{question.label}</label>
                        <Dropdown
                            selection
                            value={getAnswer(question.id).numberValue ?? ""}
                            options={selectableOptions}
                            placeholder={element.placeholder}
                            onChange={(_event, {value}) => onChange(Number(value), question.id)}/>
                    </FormField>
                )
            })}
        </div>
    )
}

interface SelectAnswerOptionProps {
    element: SelectSurveyElement;
    pushAnswer: (answer: SurveyAnswer) => void;
    getAnswer: (questionId: string) => SurveyAnswer;
}

export default DropdownSurveyComponent;
