import React from "react";
import {
    SelectSurveyElement,
    CheckboxSurveyElement,
    InputSurveyElement,
    RatingSurveyElement,
    ScaleSurveyElement,
    SurveyAnswer,
    SurveyElement
} from "../../../types";
import {
    DropdownSurveyComponent,
    InputSurveyComponent,
    RatingSurveyComponent,
    ScaleSurveyComponent,
    CheckboxSurveyComponent
} from "../../../components";
import {Form} from "semantic-ui-react";

export const AnswerOptionComponent = ({element, pushAnswer, getAnswer}: AnswerOptionComponentProps) => {


    const createAnswerOption = () => {
        switch (element.type) {
            case "DROPDOWN":
                return <DropdownSurveyComponent element={element as SelectSurveyElement} pushAnswer={pushAnswer}
                                                getAnswer={getAnswer}/>;
            case "FREETEXT":
                return <InputSurveyComponent element={element as InputSurveyElement} pushAnswer={pushAnswer}
                                             getAnswer={getAnswer}/>
            case "RATING":
                return <RatingSurveyComponent element={element as RatingSurveyElement} pushAnswer={pushAnswer}
                                              getAnswer={getAnswer}/>;
            case "MULTIPLE":
                return <CheckboxSurveyComponent element={element as CheckboxSurveyElement} pushAnswer={pushAnswer}
                                                getAnswer={getAnswer}/>;
            case "SCALE":
                return <ScaleSurveyComponent element={element as ScaleSurveyElement} pushAnswer={pushAnswer}
                                             getAnswer={getAnswer}/>
        }
    }

    return (
        <Form widths="equal">
            {createAnswerOption()}
        </Form>
    )


}

interface AnswerOptionComponentProps {
    element: SurveyElement;
    pushAnswer: (answer: SurveyAnswer) => void;
    getAnswer: (questionId: string) => SurveyAnswer;
}

