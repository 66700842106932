import React from "react";
import {CheckboxSurveyElement, SurveyAnswer} from "../../../types";
import {Checkbox, List, ListItem} from "semantic-ui-react";

export const CheckboxSurveyComponent = ({element, getAnswer, pushAnswer}: CheckboxSurveyComponentProps) => {

    const onChange = (value: boolean, questionId: string) => {
        pushAnswer({questionId: questionId, booleanValue: value} as SurveyAnswer)
    }

    return (
        <List>
            {element.questions.map(question => {
                const checked = getAnswer(question.id).booleanValue ?? false
                return (
                    <ListItem key={question.id}>
                        <Checkbox
                                  checked={checked}
                                  onChange={(event, data) => onChange(data.checked ?? false, question.id)}
                                  label={<label>{question.label}</label>} value={question.id}/>
                    </ListItem>
                )
            })}
        </List>
    )
}
export default CheckboxSurveyComponent;

interface CheckboxSurveyComponentProps {
    element: CheckboxSurveyElement;
    pushAnswer: (answer: SurveyAnswer) => void;
    getAnswer: (questionId: string) => SurveyAnswer;
}