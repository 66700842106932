import React, {useCallback} from "react";
import {AnswerOption, ScaleSurveyElement, SurveyElement} from "../../../types";
import {Form} from "semantic-ui-react";
import {CreateAnswerOptionForm} from "../CreateAnswerOptionForm/CreateAnswerOptionForm";

export const ScaleElementForm = ({element, setElement, setStep}: ScaleElementFormProps) => {

    const setOptions = useCallback((options: AnswerOption[]) => {
        setElement({...element, options: options})
    }, [element, setElement])

    return <Form>
        <Form.Input type="number" value={element.step} width={3} label={"Step"} onChange={((event, data) => setStep(Number(data.value)))}/>
        <CreateAnswerOptionForm options={element.options ?? []} setOptions={setOptions} />
    </Form>
}

type ScaleElementFormProps = {
    element: ScaleSurveyElement
    setElement: (element: SurveyElement) => void
    setStep: (step: number) => void
}