import React, {useEffect, useState} from 'react';
import {Grid, GridColumn, GridRow, Header, Icon, Segment} from "semantic-ui-react";
import {Page, Survey, SurveyAnswer} from "../../types"
import {SurveyPage, NavigationBar} from "../../components"
import {NotFound} from "../NotFound/NotFound";
import {getSurveyById} from "../../api/surveys";
import {useNavigate, useParams} from "react-router-dom";
import {CreateParticipationAnswersBody, postParticipationWithAnswers} from "../../api/participations";
import {CodeVerifier} from "../CodeVerifier/CodeVerifier";

export const SurveyComponent = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [survey, setSurvey] = useState<Survey>({} as Survey);
    const [currentPageID, setCurrentPageID] = useState(0);
    const [completedPages, setCompletedPages] = useState<number[]>([])
    const [isFetching, setIsFetching] = useState(true)
    const [verified, setVerified] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const [surveyAnswers, setSurveyAnswers] = useState<Map<string, SurveyAnswer>>(new Map())

    const pushSurveyAnswer = (answer: SurveyAnswer) => {
        const newAnswers = new Map(surveyAnswers)
        newAnswers.set(answer.questionId, answer)
        setSurveyAnswers(newAnswers)
    }

    const getAnswer = (questionId: string): SurveyAnswer | undefined => {
        return surveyAnswers.get(questionId)
    }



    const postAnswers = () => {
        const answers = Array.from(surveyAnswers.values())
        const body = {answers: answers} as CreateParticipationAnswersBody
        postParticipationWithAnswers(body).then(_ => navigate("/success"), _ => alert("failed"))
    }

    useEffect(() => {
        getSurveyById(id ?? "").then(
            value => {
                setSurvey({...value.data, pages: value.data.pages.sort((a: Page,b: Page) => a.sortId - b.sortId)})
                //manually set all multiple question to false to avoid validation errors
                const defaultAnswers = new Map()
                value.data.pages.forEach((page: Page) => page.elements.forEach(elem => {
                    switch (elem.type) {
                        case "MULTIPLE":
                            elem.questions.forEach(q => defaultAnswers.set(q.id, {questionId: q.id, booleanValue: false} as SurveyAnswer))
                            break;
                        case "SCALE":
                            elem.questions.forEach(q => defaultAnswers.set(q.id, {questionId: q.id, numberValue: 0.0} as SurveyAnswer))
                            break;
                        case "FREETEXT":
                            elem.questions.forEach(q => defaultAnswers.set(q.id, {questionId: q.id, textValue: ""} as SurveyAnswer))
                            break;
                    }

                }))
                setSurveyAnswers(defaultAnswers)
                setIsFetching(false)
            }
            ,_ => {
                setNotFound(true)
                setIsFetching(false)
            }
        )
    }, [id]);

    const nextPage = (finished: boolean) => {
        if(finished)
            postAnswers();
        else {
            setCompletedPages(completedPages.concat(currentPageID));
            setCurrentPageID(currentPageID + 1);
        }
    }

    const previousPage = () => {
        if(currentPageID !== 0)
            setCurrentPageID(currentPageID - 1);
    }

    const onStepClick = (i: number) => {setCurrentPageID(i)}

    return (
        <div>
            <Segment basic loading={isFetching}>
                {!verified && !notFound && !isFetching && <CodeVerifier expected={survey.accessCode} setVerified={setVerified}/>}
                {!isFetching && !verified && notFound && <NotFound/>}
                {!isFetching && !notFound && verified && <Grid stackable>
                    <GridRow>
                        <GridColumn>
                            <Header  as={"h2"}> <Icon className={"fa-" + survey.icon} color={"teal"}/>
                                <Header.Content>{survey.label}</Header.Content>
                            </Header>
                            <Header>{survey.title}</Header>
                        </GridColumn>
                    </GridRow>
                    <GridRow only="mobile">
                        <GridColumn><Header as={"h3"}><Icon className={"fa-" + survey.pages[currentPageID].icon} color="teal"/>
                            <Header.Content>{survey.pages[currentPageID].label}</Header.Content>
                        </Header>
                            <label>Seite {currentPageID+1} von {survey.pages.length}</label>
                        </GridColumn>

                    </GridRow>
                    <GridRow>
                        <GridColumn width="3" only="computer">
                            <NavigationBar
                                pages={survey.pages}
                                completedElements={completedPages} activeElement={currentPageID}
                                onStepClick={onStepClick}/>
                        </GridColumn>
                        <GridColumn width="16" computer="11">
                            <SurveyPage page={survey.pages[currentPageID]} currentPageIndex={currentPageID}
                                        nextPage={nextPage} previousPage={previousPage} lastPage={survey.pages.length-1} pushAnswer={pushSurveyAnswer} getAnswer={getAnswer}/>
                            <br/>

                        </GridColumn>
                    </GridRow>
                </Grid>}
            </Segment>
        </div>
    )

}