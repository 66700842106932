import React from "react";
import {Container, Header} from "semantic-ui-react";
import {SurveyAnswer, SurveyElement} from "../../../types";
import {Markdown, AnswerOptionComponent} from "../../../components";


export const SurveyElementComponent = ({getAnswer, element, pushAnswer}: SurveyElementProps) => {




    return (
        <Container>
            {element.label && <Header as="h3">{element.label}{element.required && element.type !== "NONE" &&
            <label className="required" > *</label>} </Header>}
            {element.text && <Markdown source={element.text}/>}
            {element.text && <br/>}
            <AnswerOptionComponent element={element}  pushAnswer={pushAnswer} getAnswer={getAnswer}/>
            <br/>
            <br/>
        </Container>
    )
}


interface SurveyElementProps {
    element: SurveyElement;
    pushAnswer: (answer: SurveyAnswer) => void;
    getAnswer: (questionId: string) => SurveyAnswer;
}

export default SurveyElementComponent;