import React, {useCallback, useContext, useState} from "react";
import {Button, Dropdown, Form, FormField, Header, Icon, Input, Message, Modal} from "semantic-ui-react";
import {Text} from "../../../atomic";
import {Survey} from "../../../types";
import {LanguageContext} from "../../../context";
import {createBlankSurvey, CreateBlankSurveyBody, createSurveyFromTemplate} from "../../../api/surveys";
import {useNavigate} from "react-router-dom";

export const CreateSurveyModal = ({open, setOpen, surveys, setSurveys}: CreatePageModalProps) => {

    const {dictionary} = useContext(LanguageContext)
    const [newSurvey, setNewSurvey] = useState<CreateBlankSurveyBody>({} as CreateBlankSurveyBody)
    const [templateId, setTemplateId] = useState("")
    const [creating, setCreating] = useState(false)
    const [error, setError] = useState("")
    const navigate = useNavigate()

    const handleCreate = useCallback(() => {
        if (templateId === "no template" || templateId === "") {
            setCreating(true)
            createBlankSurvey(newSurvey).then(
                value => {
                    setTimeout(() => {
                        setSurveys(value.data)
                        setCreating(false)
                        setOpen(false)
                        setNewSurvey({} as CreateBlankSurveyBody)
                    }, 200)
                },
                reason => {
                    if (reason.response.status === 440)
                        navigate("/admin/login/sessionExpired=true")
                    else {
                        setError("somethingWentWrong")
                        setCreating(false)
                    }
                }
            )
        } else {
            const template = surveys.find(s => s.id === templateId) ?? {} as Survey
            setCreating(true)
            createSurveyFromTemplate({...newSurvey, template: template}).then(
                value => {
                    setSurveys(value.data)
                    setCreating(false)
                    setOpen(false)
                },
                reason => {
                    if (reason.response.status === 440)
                        navigate("/admin/login/sessionExpired=true")
                    else {
                        setError("somethingWentWrong")
                        setCreating(false)
                    }
                }
            )
        }
    }, [templateId, newSurvey, setSurveys, setOpen, navigate, surveys, setError])


    const templateOptions = [...surveys.map(
        survey => {
            return {key: survey.id, text: survey.label, value: survey.id}
        }
    ), {key: "-1", text: "No Template", value: "no template"}]

    return (
        <Modal closeIcon open={open} onClose={() => {
            setOpen(false)
            setError("")
            setCreating(false)
        }} onOpen={() => setOpen(true)}>
            <Modal.Header>
                <Header>
                    <Icon className="fa-solid fa-comments" color="teal"/>
                    <Header.Content>
                        <Text textId={"createNewSurvey"}/>
                    </Header.Content>
                </Header>
            </Modal.Header>
            <Modal.Content>
                <Form error={error !== ""}>
                    <FormField>
                        <label><Text textId={"label"}/></label>
                        <Input onChange={((event, data) => setNewSurvey({...newSurvey, label: data.value}))}/>
                    </FormField>
                    <FormField>
                        <label><Text textId={"title"}/></label>
                        <Input onChange={((event, data) => setNewSurvey({...newSurvey, title: data.value}))}/>
                    </FormField>
                    <FormField>
                        <label><Text textId={"icon"}/></label>
                        <Input onChange={((event, data) => setNewSurvey({...newSurvey, icon: data.value}))}/>
                    </FormField>
                    <FormField>
                        <label><Text textId={"adminPassword"}/></label>
                        <Input onChange={((event, data) => setNewSurvey({...newSurvey, adminCode: data.value}))}/>
                    </FormField>
                    <FormField>
                        <label><Text textId={"template"}/></label>
                        <Dropdown selection options={templateOptions}
                                  onChange={((event, data) => setTemplateId(String(data.value)))}/>
                    </FormField>
                    <Message error>
                        <Message.Header><Text textId={error}/></Message.Header>
                    </Message>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button icon="fa-solid fa-times" content={dictionary.cancel} onClick={() => setOpen(false)}/>
                <Button icon="fa-solid fa-check" content={dictionary.create} color="teal" loading={creating}
                        onClick={() => handleCreate()}/>
            </Modal.Actions>
        </Modal>
    )
}

type CreatePageModalProps = {
    open: boolean
    setOpen: (open: boolean) => void
    surveys: Survey[]
    setSurveys: (surveys: Survey[]) => void
}
