import {Segment} from "semantic-ui-react";
import React from "react";
import {Page, SurveyAnswer} from "../../../types";
import {SurveyElementComponent, SurveyControls} from "../../../components"

export const SurveyPage = ({
                               page,
                               nextPage,
                               previousPage,
                               lastPage,
                               currentPageIndex,
                               pushAnswer,
                               getAnswer
                           }: SurveyPageProps) => {


    const validateInputs = (): boolean => {
        let valid = true
        for (let element of page.elements) {
            for(let question of element.questions) {
                if(element.required) {
                    valid = valid && hasValue(getAnswer(question.id))
                    if(!valid)
                        return false
                }
            }
        }
        return valid
    }
    const hasValue = (answer: SurveyAnswer | undefined) => {
        if(answer === undefined || answer === null)
            return false
        return answer.numberValue != null || answer.textValue != null || answer.booleanValue != null
    }

    const onClick = () => {
        let finished = false
        if (currentPageIndex === lastPage)
            finished = true



        nextPage(finished);
        window.scrollTo(0, 0);
    }
    const getAnswerOrDefault = (questionId: string): SurveyAnswer => {
        return getAnswer(questionId) ?? {} as SurveyAnswer
    }
    return (<Segment raised>
            <Segment basic>
                {page.elements.sort(((a, b) => a.sortId - b.sortId)).map(element => <SurveyElementComponent
                    key={element.id}
                    element={element}
                    pushAnswer={pushAnswer}
                    getAnswer={getAnswerOrDefault}/>
                )}
            </Segment>
            <SurveyControls isLastPage={currentPageIndex === lastPage} pageID={currentPageIndex}
                            validateInputs={validateInputs}
                            onNextClick={onClick} onPreviousClick={previousPage}/>
        </Segment>

    )
}


interface SurveyPageProps {
    page: Page;
    nextPage: (finished: boolean) => void;
    previousPage: () => void;
    lastPage: number;
    currentPageIndex: number;
    pushAnswer: (answer: SurveyAnswer) => void;
    getAnswer: (questionId: string) => SurveyAnswer | undefined;
}
