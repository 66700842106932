import React, {useContext} from "react";
import {LanguageContext} from "../../context";

export const Text = ({textId}: TextProps) => {
    const languageContext = useContext(LanguageContext)

    return <div>
        {languageContext.dictionary[textId] || textId}
    </div>
}

type TextProps = {
    textId: string
}