import React, {useCallback, useContext, useState} from "react";
import {
    Button,
    Form,
    FormGroup, Header,
    Table,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow
} from "semantic-ui-react";
import {Question} from "../../../types/Question";
import {containsValues} from "../../../util";
import {LanguageContext} from "../../../context";
import {Text} from "../../../atomic";

export const CreateQuestionForm = ({questions, setQuestions}: CreateQuestionFormProps) => {

    const [newQuestion, setNewQuestion] = useState<Question>({} as Question)
    const {dictionary} = useContext(LanguageContext)

    const handleAdd = useCallback(() => {
        setQuestions([...questions, newQuestion])
    }, [setQuestions, questions, newQuestion])

    const handleRemove = useCallback((value: number) => {
        setQuestions(questions.filter((option, index) => index !== value))
    }, [setQuestions, questions])

    return <div>
        <Header as="h3">{dictionary.questions}</Header>
        <FormGroup className="create-answeroption-wrapper">
            <Form.Input value={newQuestion.label} width={3} label={dictionary.label} onChange={(event, data) => setNewQuestion({...newQuestion, label: String(data.value)})}/>
            <Form.Input value={newQuestion.key} width={3} label={dictionary.logicalKey} onChange={(event, data) => setNewQuestion({...newQuestion, key: String(data.value)})}/>
            <Form.Button content={dictionary.addQuestion} onClick={handleAdd} color={"teal"}/>
        </FormGroup>
        <Table basic="very">
            <TableHeader>
                <TableHeaderCell width={6}><Text textId={"label"}/></TableHeaderCell>
                <TableHeaderCell width={6}><Text textId={"logicalKey"}/></TableHeaderCell>
                <TableHeaderCell width={2}/>
            </TableHeader>
            {!containsValues(questions) ? <p>No questions yet</p> : questions.map((question, index) =>
                <TableRow key={index}>
                    <TableCell>{question.label}</TableCell>
                    <TableCell>{question.key}</TableCell>
                    <TableCell><Button onClick={() => handleRemove(index)} icon="fa-solid fa-trash" color="red"/></TableCell>
                </TableRow>
            )}
        </Table>
    </div>

}

type CreateQuestionFormProps = {
    setQuestions: (questions: Question[]) => void;
    questions: Question[]
}
