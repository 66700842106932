import React, {useContext} from "react";
import {Dropdown} from "semantic-ui-react";
import {LanguageContext} from "../../context";
import "./LanguageSelector.css"

const languageOptions = [
    {key: "de", text: "Deutsch", value: "de"},
    {key: "en", text: "English", value: "en"}
]
export const LanguageSelector = () => {
    const {language, updateLanguage} = useContext(LanguageContext)
    return (
        <div>
            <Dropdown
                className='language-select'
                floating
                options={languageOptions}
                value={language}
                onChange={(event, data) => updateLanguage(String(data.value))}
            />
        </div>

    )
}